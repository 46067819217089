<template>
  <v-btn
    v-if="create"
    class="pointer-events-none"
  >
    <span :class="statusTextClass">
      {{'data.status'| t}} ({{statusLabel}})
    </span>
    <v-icon :class="statusTextClass">
      fa fa-server
    </v-icon>
  </v-btn>
</template>

<script>
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  computed: {
    create() {
      if(this.loadFailure) return false
      if(this.dataAction.status === false) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>